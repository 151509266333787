const parseDate = (str) => {
  if (!str) {
    return null
  }

  const date = str.split(/[^\d]/).map((i) => parseInt(i))

  if (date.length === 1) {
    return parseInt([date[0], '01', '01'].join(''))
  } else if (date.length === 2) {
    return parseInt([date[0], pad(date[1], 2, '0'), '01'].join(''))
  } else {
    return parseInt([date[0], pad(date[1], 2, '0'), pad(date[2], 2, '0')].join(''))
  }
}

const floatToDate = (integer) => {
  return new Date(floatToDateString(integer))
}

const floatToDateString = (float) => {
  const str = float.toString()

  if (str.length <= 4) {
    return `${str.slice(0, 4)}/01/01`
  } else if (str.length <= 6) {
    return `${str.slice(0, 4)}/${pad(parseInt(str.slice(4, 6)), 2, '0')}/01`
  } else {
    return `${str.slice(0, 4)}/${pad(parseInt(str.slice(4, 6)), 2, '0')}/${str.slice(6, 8)}`
  }
}

const dateToInteger = (date) => {
  return parseInt(`${date.getFullYear()}${pad(date.getMonth() + 1, 2, '0')}${pad(date.getDate(), 2, '0')}`)
}

const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const formatAmount = (amount, currency) => {
  if (currency === '$') {
    return formatterUSD.format((Math.round(amount * 100) / 100).toFixed(2))
  } else if (currency === 'CAD' || currency === 'USD') {
    return `${formatterUSD.format((Math.round(amount * 100) / 100).toFixed(2)).replace(/\$/, '')} ${currency}`
  } else {
    return `${amount} ${currency}`
  }
}

const sortByLength = (a, b) => {
  return b.length - a.length
}

const sortByKey = (obj) => {
  const newObj = {}
  const keys = Object.keys(obj).sort()

  keys.forEach((key) => {
    newObj[key] = obj[key]
  })

  return newObj
}

const separatorLength = (account) => {
  return (account.match(/:/g) || []).length
}

const sumDirectChildren = (data, account) => {
  const accounts = Object.keys(data).filter((acc) => acc.startsWith(account) && separatorLength(acc) <= separatorLength(account) + 1)
  const currencies = {}

  accounts.forEach((acc) => {
    for (const currency in data[acc]) {
      if (typeof currencies[currency] === 'undefined') {
        currencies[currency] = 0
      }

      currencies[currency] += data[acc][currency]
    }
  })

  return currencies
}

const includeRoots = (obj) => {
  const newObj = Object.assign({}, obj)
  const accounts = Object.keys(newObj)
  const parentsToBeAdded = []

  accounts.forEach((account) => {
    const splat = account.split(':')

    for (var i = 1; i < splat.length; i++) {
      const parentAccount = splat.slice(0, i).join(':')

      if (parentsToBeAdded.indexOf(parentAccount) === -1) {
        parentsToBeAdded.push(parentAccount)
      }
    }
  })

  parentsToBeAdded.sort(sortByLength).forEach((parentAccount) => {
    newObj[parentAccount] = sumDirectChildren(newObj, parentAccount)
  })

  return newObj
}

const formatAccount = (account) => {
  const bareAccount = account.substring(account.lastIndexOf(':') + 1)

  return `${account.split(':').map(() => '  ').join('')}|- ${bareAccount}`
}

const pad = (str, digits = 24, char = ' ', addCharAfter = false) => {
  const startingDigits = str.toString().length
  let requiredDigits = digits - startingDigits

  if (str.length > digits) {
    return str.slice(0, digits)
  }

  while (requiredDigits > 0) {
    if (addCharAfter) {
      str += char
    } else {
      str = char + str
    }

    requiredDigits--
  }

  return str
}

const padFromRight = (str, digits, char, addCharAfter) => {
  if (str.length <= digits) {
    return pad(str, digits, char, addCharAfter)
  }

  str = str.slice(str.length - digits, str.length).trim()

  return pad(str, digits, char, addCharAfter)
}

const matchesAccount = (account, matchAccount) => {
  if (!matchAccount) {
    return true
  }

  return account.indexOf(matchAccount) === 0
}

const isTransactionForAccount = (transaction, matchAccount) => {
  return transaction.postings.filter((p) => matchesAccount(p.account, matchAccount)).length > 0
}

const postingsForAccount = (postings, matchAccount) => {
  return postings.filter((p) => matchesAccount(p.account, matchAccount))
}

const matchesAccounts = (account, matchAccounts) => {
  if (!matchAccounts || !matchAccounts.length) {
    return true
  }

  for (var i = 0; i < matchAccounts.length; i++) {
    if (matchesAccount(account, matchAccounts[i])) {
      return true
    }
  }

  return false
}

const postingsForAccounts = (postings, matchAccounts) => {
  return postings.filter((p) => matchesAccounts(p.account, matchAccounts))
}

const sumPostingsForCurrency = (postings, currency) => {
  return postings
    .filter((posting) => posting.currency === currency)
    .reduce((acc, posting) => acc + posting.amount, 0)
}

const matchesDate = (date, startAt, endAt) => {
  if (!startAt && !endAt) return true
  if (!endAt) return date >= startAt
  if (!startAt) return date < endAt

  return date >= startAt && date < endAt
}

const getStartOfMonth = (date) => {
  date = new Date(date)
  return new Date(date.getFullYear(), date.getMonth())
}

const getEndOfMonth = (date) => {
  date = new Date(date)
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  end.setHours(23)
  end.setMinutes(59)
  end.setSeconds(59)
  end.setMilliseconds(999)

  return end
}

const getEndOfYear = (date) => {
  date = new Date(date)
  const end = new Date(date.getFullYear(), 11, 31)

  end.setHours(23)
  end.setMinutes(59)
  end.setSeconds(59)
  end.setMilliseconds(999)

  return end
}

const getStartOfYear = (date) => {
  return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0, 0, 0)
}

const commandArgs = (filters) => {
  return `${filters.account ? ` "${filters.account}"` : ''}${filters.payee ? ` "${filters.payee}" ` : ''}${filters.accounts ? `${filters.accounts.join(' ')} ` : ''}${filters.startAtString ? ` --begin ${filters.startAtString.replace(/-/g, '/')}` : ''}${filters.endAtString ? ` --end ${filters.endAtString.replace(/-/g, '/')}` : ''}${filters.showCleared ? ' --cleared' : ''}`
}

const sortByAmountExtremes = (a, b) => {
  if (a.currency !== b.currency) {
    return a.currency.localeCompare(b.currency)
  } else if (a.amount < 0 && b.amount < 0) {
    return a.amount - b.amount
  } else if (a.amount < 0) {
    return -1
  } else if (b.amount < 0) {
    return 1
  } else {
    return b.amount - a.amount
  }
}

export {
  commandArgs,
  dateToInteger,
  floatToDate,
  floatToDateString,
  formatAccount,
  formatAmount,
  getStartOfMonth,
  getStartOfYear,
  getEndOfMonth,
  getEndOfYear,
  includeRoots,
  matchesAccount,
  matchesAccounts,
  postingsForAccount,
  postingsForAccounts,
  isTransactionForAccount,
  matchesDate,
  pad,
  padFromRight,
  parseDate,
  sortByKey,
  sortByAmountExtremes,
  sumPostingsForCurrency
}
